import React from "react";

const Hobbies = () => {
  return (
    <div className="hobbies">
      <h3>Intérêts</h3>
      <ul>
        <li className="hobby">
          <i className="fas fa-guitar"></i>
          <span>Musique</span>
        </li>
        <li className="hobby">
          <i className="fas fa-book"></i>
          <span>Lecture</span>
        </li>
        <li className="hobby">
          <i className="fas fa-hiking"></i>
          <span>Sport</span>
        </li>
        
      </ul>
    </div>
  );
};

export default Hobbies;
