import React from "react";

const Experience = () => {
  return (
    <div className="experience">
      <div className="exp">
        <h3 className="h3-exp">Expérience</h3>
        <div className="tra">
          <h4>Développeur web Back-End JavaScript </h4>
          <h5>Ecole O'Clock - Janvier 2022</h5>
          <p>
            Création réalisation d'un site web au sein d'une
            équipe de 5 développeurs :
            <a
              href="https://mymusicalworld.surge.sh/"
              target="_blank"
              rel="noopener noreferrer"
            >
              MY MUSICAL WORLD
            </a>
          </p>
        </div>
      </div>
      <div className="training">
        <h3 className="h3-tra">Formation</h3>
        <div className="tra">
          <h4>Concepteur développeur d'application</h4>
          <h5>Ecole O'Clock - Octobre 2022/Février 2024</h5>

          <p>721 heures - POO, PHP, JavaScript</p>
        </div>

        <div className="tra">
          <h4>
            Développeur web et web mobile fullstack JavaScript
          </h4>
          <h5>Ecole O'Clock - Aout 2021 / Février 2022</h5>

          <p>
            700 heures - Html, Css, JavaScript, Node.js,
            Express.js, PSQL, Spécialisation API & Data
          </p>
        </div>

        <div className="tra">
          <h4>Certification Opquast</h4>
          <h5>Avril 2022</h5>

          <p>
            Maîtrise de la qualité en projet web, Niveau Avancé :
            <a
              href="https://directory.opquast.com/fr/certificat/FQPS6X/"
              target="_blank"
              rel="noopener noreferrer"
            >
              FQPS6X
            </a>
          </p>
        </div>
      </div>
    </div>
  );
};

export default Experience;
