export const portfolioData = [
  {
    id: 1,
    name: 'boardgameAPI',
    languages: ['javascript','node'],
    languagesIcons: ['fab fa-js','fab fa-node'],
    source: 'http://www.github.com',
    info: `API élaborée durant la formation O'Clock, qui permet de communiquer avec la base de données grâce à des requêtes de type "CRUD" (Create, Read, Update, Delete)`,
    picture: './media/boardgame.jpg'
  },
  
  {
    id: 2,
    name: 'mymusicalworld.surge.sh',
    languages: ['javascript','react','css', 'node'],
    languagesIcons: ['fab fa-js','fab fa-react','fab fa-css3-alt','fab fa-node'],
    source: 'https://mymusicalworld.surge.sh/',
    info: `Projet de fin de formation réalisé au sein d'une équipe de 5 développeurs, permettant de se connecter ou de s'inscrire afin de se créer une bibliothèque musicale (via requêtes à l'API Deezer) et de partager notre bibliothèque`,
    picture: './media/mymusicalworld.png'
  },
  {
    id: 3,
    name: `Maison Jungle`,
    languages: ['javascript','react'],
    languagesIcons: ['fab fa-js','fab fa-react'],
    source: 'https://github.com/ValR08813/react-openClassroom-MaisonJungle',
    info: `Application web réalisé avec OpenClassroom afin d'apprendre l'utilisation de React.`,
    picture: './media/maisonJungle.png'
  },

  // {
  //   id: 4,
  //   name: 'Idiomass',
  //   languages: ['javascript','react', 'css', 'node'],
  //   languagesIcons: ['fab fa-js','fab fa-react','fab fa-css3-alt','fab fa-node'],
  //   source: 'http://www.github.com',
  //   info: `Projet d'application permettant de se connecter et de s'inscrire à un site, d'ajouter du vocabulaire dans la langue choisie, puis de répondre à un questionnaire concernant le vocabulaire. En cours de production`,
  //   picture: './media/TRAVAUX.jpg'
  // },
  // {
  //   id: 5,
  //   name: 'Démineur',
  //   languages: ['javascript','react','css'],
  //   languagesIcons: ['fab fa-js','fab fa-react','fab fa-css3-alt'],
  //   source: 'http://www.github.com',
  //   info: `Projet de développement d'un jeu de démineur. En cours de production`,
  //   picture: './media/TRAVAUX.jpg'
  // },
  // {
  //   id: 6,
  //   name: 'Compteur',
  //   languages: ['javascript','css','node'],
  //   languagesIcons: ['fab fa-js','fab fa-css3-alt','fab fa-node'],
  //   source: 'http://www.github.com',
  //   info: `Projet d'API REST permettant d'ajouter des citations/répliques de films ou de livres ou autre. En cours de production.`,
  //   picture: './media/TRAVAUX.jpg'
  // },
  
  
]