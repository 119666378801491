import React from "react";

const OtherSkills = () => {
  return (
   
      <div className="otherSkills">
        <h3>Autres Compétences</h3>
          <div className="list">
            <ul>
              <li>
                <i className="fas fa-check-square"></i>
                Anglais courant
              </li>
              <li>
                <i className="fas fa-check-square"></i>
                Espagnol débutant
              </li>
              <li>
                <i className="fas fa-check-square"></i>
                GitHub
              </li>
              <li>
                <i className="fas fa-check-square"></i>
                PostgreSQL
              </li>
             
            </ul>
            <ul>
            <li>
                <i className="fas fa-check-square"></i>
                Méthodes Agiles
              </li>
              <li>
                <i className="fas fa-check-square"></i>
                Sqitch
              </li>
              <li>
                <i className="fas fa-check-square"></i>
                API
              </li>
            </ul>
          </div>
        
      </div>
   
  );
};

export default OtherSkills;
